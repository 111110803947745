<template>
	<div class="distribution-wrap">
		<el-form inline>
			<el-form-item label="" class="collapseCascader">
				<el-col :span="20">
					<el-cascader v-model="form.organList" ref="cascaderRef" placeholder="班级" :options="optionsData"
						:props="cascaderProps" collapse-tags filterable :style="{ width: '160px' }"
						class="expand-filter-cascader" @change="handleChange" :append-to-body="false">
					</el-cascader>
				</el-col>
			</el-form-item>
			<el-form-item label="">
				<el-select v-model="form.allocationStatus" clearable filterable @change="handleSubmit" placeholder="状态"
					:style="{ width: '160px' }">
					<!-- <el-option value="" label="全部"></el-option> -->
					<el-option value="0" label="待分配"></el-option>
					<el-option value="1" label="已分配"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="">
				<el-select v-model="form.sex" clearable filterable @change="handleSubmit" placeholder="性别"
					:style="{ width: '160px' }">
					<!-- <el-option value="" label="全部"></el-option> -->
					<el-option value="1" label="男"></el-option>
					<el-option value="2" label="女"></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-table ref="table" height="400" :data="list" style="width: 100%" @selection-change="handleSelectionChange"
			:empty-text="textTitle">
			<el-table-column type="selection" width="55"></el-table-column>
			<!-- <el-table-column type="index" label="序号" width="80"></el-table-column> -->
			<el-table-column prop="studentName" label="姓名" align="center" width="130px"></el-table-column>
			<el-table-column prop="sex" label="性别" align="center">
				<template slot-scope="{row}">
					{{ row.sex | filterSex }}
				</template>
			</el-table-column>
			<el-table-column prop="address" label="分配状态" align="center">
				<template slot-scope="{row}">
					{{ row.allocationStatus | filterStatus }}
				</template>
			</el-table-column>
			<el-table-column prop="address" label="宿舍" align="center">
				<template slot-scope="{row}">
					<span v-if="row.roomName">{{ row.roomName }}</span>
					<span v-else>-</span>
				</template>
			</el-table-column>
		</el-table>
		<div style="text-align: right; margin-top: 20px">
			<el-button @click="handleClose" type="enquiry">取消</el-button>
			<el-button @click="submitForm" type="primary">确定</el-button>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		form: {
			type: Object,
		},
		list: {
			type: Array,
		},
		optionsData: {
			type: Array,
		},
		empyCount: {
			type: Number,
		},
	},
	data() {
		return {
			cascaderProps: {
				value: "id",
				label: "name",
				multiple: true,
				emitPath: false,
			},
			selectData: [],
		};
	},
	filters: {
		filterSex(value) {
			switch (value) {
				case "1":
					return "男";
				case "2":
					return "女";
				default:
					return "-";
			}
		},
		filterStatus(value) {
			switch (value) {
				case "0":
					return "待分配";
				case "1":
					return "已分配";
				default:
					return "-";
			}
		},
	},
	computed: {
		textTitle() {
			return this.form.organList.toString() == "" ? "请选择班级" : "暂无数据";
		},
	},
	methods: {
		handleChange() {
			this.$nextTick(() => {
				const classList = this.$refs.cascaderRef
					.getCheckedNodes()
					.filter((i) => i.data.organType === "4");
				this.form.organList = classList.map((i) => i.data.id);
				this.handleSubmit();
			});
		},
		handleSubmit() {
			this.$emit("getStuTableList", this.form);
		},
		handleSelectionChange(val) {
			this.selectData = val;
			if (this.selectData.length > this.empyCount) {
				this.$message.warning("所选学生人数超过当前宿舍剩余床位");
			}
			this.$emit("handleSelectionChange", val);
		},
		submitForm() {
			if (this.selectData.length > this.empyCount) {
				this.$message.warning("所选学生人数超过当前宿舍剩余床位");
			} else {
				this.$eventBus.$emit("dialogTableSort", this.selectData);
				this.$emit("handleClose");
			}
		},
		handleClose() {
			this.$emit("handleClose");
		},
	},
};
</script>

<style scoped lang="scss">
.collapseCascader {
	::v-deep .el-col-20 {
		width: 100%;
	}
}

::v-deep .expand-filter-cascader {
	width: 100%;

	.el-cascader__tags {
		display: inline-flex;
		flex-wrap: nowrap;
		align-items: center;
		/* margin-right: 50px; */

		.el-tag {
			max-width: calc(100% - 50px);

			+.el-cascader__search-input {
				margin-left: 6px;
				min-width: 7px;
			}
		}
	}
}

::v-deep .el-cascader_single {
	.el-cascader__tags {
		.el-tag {
			max-width: 100%;
			width: 100%;
		}

		.el-cascader__search-input {
			display: none;
		}
	}
}

::v-deep {
	.el-input__inner {
		background: #f5f5f5;
		border: transparent 1px solid;
	}

	.el-cascader__search-input {
		background: #f5f5f5;
	}

	.el-input__inner:focus {
		border: #3c7fff 1px solid;
		background-color: #fff;
	}
}
</style>
<style lang="scss">
.distribution-wrap {
	.el-cascader-panel {
		height: 200px !important;
	}
}
</style>
