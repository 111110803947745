<template>
    <div>
        <el-form inline>
            <el-form-item label="">
                <el-select v-model="form.dormitoryId" clearable filterable @change="handleDormitoryChange" placeholder="宿舍"
                    :style="{ width: '160px' }">
                    <el-option v-for="(item, index) in dormitoryList" :label="item.label" :value="item.value"
                        :key="index" />
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="form.floorId" clearable filterable @change="getList" placeholder="楼层"
                    :style="{ width: '160px' }">
                    <el-option v-for="(item, index) in floorList" :label="item.label" :value="item.value" :key="index" />
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="form.roomType" clearable filterable @change="getList" placeholder="宿舍类型"
                    :style="{ width: '160px' }">
                    <!-- <el-option value="" label="全部"></el-option> -->
                    <el-option value="1" label="男"></el-option>
                    <el-option value="2" label="女"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="box">
            <ul class="header">
                <!-- <li class="cell"></li> -->
                <li class="cell">宿舍楼</li>
                <li class="cell">宿舍名</li>
                <li class="cell">宿舍类型</li>
                <li class="cell sort">
					<span>剩余床位数</span>
					<div class="btn-box">
						<div class="sort-btn sort-btn-up" :class="{'active-up': sortType=='1'}" @click="changeSort('1')"></div>
						<div class="sort-btn sort-btn-down" :class="{'active-down': sortType=='2'}" @click="changeSort('2')"></div>
					</div>
				</li>
            </ul>
            <div class="body">
                <template v-if="showList.length > 0">
                    <li
                        v-for="(item, index) in showList"
                        :key="index"
                        class="row"
                        :class="{'active-row': item.id === form.roomId}"
                        @click="handleRowClick(item, index)"
                    >
                        <div>
                            <!-- <div class="circle"></div> -->
                            <img v-if="item.id === form.roomId" :src="checkedUrl" alt="checked" class="checked-img">
                            <div v-else class="circle checked-img"></div>
                            <!-- <img v-show="item.id === form.roomId" :src="checkedUrl" alt="checked" class="checked-img"> -->
                        </div>

                        <img v-show="item.id === form.roomId" :src="checkedUrl" alt="checked" class="checked-img">
                        <!-- <span class="cell1"></span> -->
						<span class="cell">{{ item.dormitoryName }}</span>
                        <span class="cell">{{ item.roomName }}</span>
						<span class="cell">{{ item.roomType == '1' ? '男' : '女' }}</span>
						<span class="cell">{{ item.laveBedNym }}</span>

                    </li>
                </template>
                <el-empty v-else></el-empty>
            </div>
        </div>
        <div style="text-align: right;margin-top: 20px">
            <el-button @click="handleClose" type="enquiry">取消</el-button>
            <el-button @click="submitForm" type="primary">确定</el-button>
        </div>
        <!-- 选择床位号弹窗 -->
        <dialog-wrapper :visible.sync="dialogBedNumObj.dialogVisible" :dialogObj="dialogBedNumObj" @handleClose="handleBedNumClose">
            <el-form
				:model="bedNumData"
				:rules="bedNumRules"
				ref="bedNumRef"
			>
            <el-form-item :required="true" prop="bedNum">
                <div class="mb10-wrap">
                    <el-radio-group  v-model="bedNumData.bedNum" v-for="item,index in bedNumArr" :key="index">
                        <el-radio class="mb10" :disabled="item.occupation" :label="item.bedNum">{{item.bedNum}}</el-radio>
                    </el-radio-group>
                </div>
            </el-form-item>
            </el-form>
            <div style="text-align: right;margin-top: 20px">
            <el-button @click="handleBedNumClose" type="enquiry">取消</el-button>
            <el-button @click="handleSave" type="primary">确定</el-button>
        </div>
        </dialog-wrapper>
    </div>
</template>

<script>
import {
    // 业务组件
    DialogWrapper,
    debounce
} from 'common-local';
import { SchoolDormitory } from "@/models/SchoolDormitory";
import { mapState } from "vuex";
export default {
    data(){
        return{
            dialogBedNumObj: {
                title: '选择床号',
                dialogVisible: false,
                width: '400px'
            },
            bedNumData:{
                bedNum:''
            },
            bedNumRules:{
                bedNum: [
					{
						required: true,
						message: "请选择床位号",
						trigger: ["change", "blur"],
					},
				],
            },
            bedNumArr:[], // 宿舍床位列表
            sortType:'1'
        }
    },
    props: {
        form: {
            type: Object,
        },
        list: {
            type: Array,
        },
        dormitoryList: {
            type: Array
        },
        floorList: {
            type: Array
        },
    },
    components: {
        DialogWrapper
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        checkedUrl() {
            return require('@/assets/images/checked-table.png')
        },
		showList(){
			let list = this.list;
			if(this.sortType == 1){
				return list.sort((a,b) => a.laveBedNym-b.laveBedNym)
			}else{
				return list.sort((a,b) => b.laveBedNym-a.laveBedNym)
			}
		}
    },
    methods: {
        handleRowClick(item, index) {
            if (this.form.roomId === item.id) {
                this.form.roomId = ''
                this.form.roomName = ''
            } else {
                this.form.roomId = item.id
                this.form.roomName = item.roomName
            }
        },
        handleDormitoryChange(data) {
            this.$emit('dormitoryChange', data)
        },
        getList() {
            this.$emit('getList')
        },
        /**
         * @Description: 获取宿舍分配状态
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-31 14:10:40
         */
        async getBedNumStatus(){
            const schoolDormitory = new SchoolDormitory()
            await schoolDormitory.getdDormitoryBedNumStatus({roomId:this.form.roomId}).then((res)=>{
                this.bedNumArr = res.data.data
                // console.log('res.data.data',res.data.data);
            })
        },
        submitForm: debounce(async function() {
            if (this.form.roomId !== "") {
                await this.getBedNumStatus();
                // 选择床位号弹窗
                this.dialogBedNumObj.dialogVisible = true;
            } else {
                this.$message.warning("请选择宿舍");
            }
        }, 200),
        handleClose() {
            this.bedNumArr = []
            this.$emit('handleClose')
        },
        // 选择床位弹窗的确定
        handleSave: debounce(function () {
            this.$refs.bedNumRef.validate((valid) => {
				if (valid) {
                    this.$emit('submitForms',this.bedNumData.bedNum)
                    this.handleBedNumClose()
                }
            })
        }, 200),
        // 选择床位弹窗关闭
        handleBedNumClose(){
            this.dialogBedNumObj.dialogVisible = false
            this.$refs.bedNumRef.clearValidate();
            setTimeout(() => {
                this.bedNumArr = []
                this.bedNumData.bedNum = ''
            }, 300)
        },
		changeSort(type){
			// laveBedNym
			console.log(this.list);
			if(this.sortType != type){
				this.sortType = type;
			}

		}
    }
}
</script>

<style scoped lang="scss">
.circle{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #D9D9D9;
}
ul {
    list-style: none;
    margin: 0;
    padding: 0;

}

.box {
    width: 100%;
    .cell1 {
        flex: 1;
        box-sizing: border-box;
        // padding: 0 0px;
        font-size: 14px;
        color: #606266;
    }

    .cell {
        flex: 1;
        box-sizing: border-box;
        padding: 0 20px;
        font-size: 14px;
        color: #606266;
		text-align: center;
    }

    .header {
        display: flex;
		align-items: center;
        .cell1 {
            padding: 10px 0px;
            font-weight: bold;
            color: #595959;
            background-color: #f7f7f7;
        }

        .cell {
            padding: 10px 20px;
            font-weight: bold;
            color: #595959;
            background-color: #f7f7f7;
			height: 50px;
			line-height: 30px;
			text-align: center;
        }
		.sort{
			display: flex;
			align-items: center;
			justify-content: center;
			.btn-box{
				margin-left: 5px;
				display: flex;
				flex-direction: column;
				.sort-btn{
					cursor: pointer;
				}
				.sort-btn-up{
					width: 0px;
					height: 0px;
					border:8px solid #b7b7bb;
					border-top-color: transparent;
					border-right:6px solid transparent;
					border-left:6px solid transparent;
					margin-bottom:3px;
				}
				.sort-btn-down{
					width: 0px;
					height: 0px;
					border:8px solid #b7b7bb;
					border-bottom-color: transparent;
					border-left:6px solid transparent;
					border-right:6px solid transparent;
				}
				.active-up{
					border:8px solid #67aced;
					border-top-color: transparent;
					border-right:6px solid transparent;
					border-left:6px solid transparent;
				}
				.active-down{
					border:8px solid #67aced;
					border-bottom-color: transparent;
					border-left:6px solid transparent;
					border-right:6px solid transparent;
				}
			}
		}
    }

    .body {
        height: 400px;
        overflow: auto;

        li {
            position: relative;
            display: flex;
            min-height: 48px;
            align-items: center;
            border-bottom: 1px solid #ebeef5;
            cursor: pointer;
            box-sizing: border-box;
        }
    }
}

.active-row {
    border: 1px solid #3C7FFF !important;
}

.checked-img {
    position: absolute;
    left: 30px;
    top: 15px;
}

.mb10-wrap {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px 30px;
}

.mb10{
}

::v-deep {
    .el-input__inner {
        background: #f5f5f5;
        border: transparent 1px solid;
    }

    .el-cascader__search-input {
        background: #f5f5f5;
    }

    .el-input__inner:focus {
        border: #3c7fff 1px solid;
        background-color: #fff;
    }

    .el-cascader .el-input.is-focus .el-input__inner {
        background-color: #fff;
    }

    .el-cascader__search-input:focus {
        background-color: #fff;
    }
}
</style>
