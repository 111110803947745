<template>
    <!-- 宿舍管理和宿舍分配使用 -->
    <div class="operation-btn-view" :style="{'padding-top': paddingTop}">
        <div class="btn-left">
            <slot name="leftBtn">
                <!-- <el-button type="primary" @click="handleAdd" v-hasPermi="addBtn" style="margin-left: 0px;">添加</el-button>
                <el-button type="danger" @click="multiDelete" v-hasPermi="batchDeleteBtn">批量删除</el-button> -->
            </slot>
        </div>
        <div class="btn-right">
            <slot name="rightBtn"></slot>
            <el-button type="primary" @click="handleImport" class="download-btn" v-hasPermi="downLoadTempBtn">下载模板
            </el-button>
            <el-upload
                ref="upload"
                :action="action"
                :headers="headers"
                accept=".xlsx,.xls"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :with-credentials="true"
                :show-file-list="false"
                :data="{schoolId: schoolId}"
                class="upload-demo"
            >
                <el-button type="enquiry" v-hasPermi="importBtn">
                    <span>导入</span>
                </el-button>
            </el-upload>
            <el-button type="enquiry" @click="handleExport" v-hasPermi="exportBtn">
                <span>导出</span>
            </el-button>
        </div>
    </div>
</template>

<script>

import {getToken} from "@/libs/auth";
import {mapState} from "vuex";

export default {
    name: "OperationBtnView",
    props: {
        action: {
            type: String
        },
        paddingTop: {
            type: String
        },
        addBtn: {
            type: Array
        },
        batchDeleteBtn: {
            type: Array
        },
        downLoadTempBtn: {
            type: Array
        },
        importBtn: {
            type: Array
        },
        exportBtn: {
            type: Array
        }
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
        }),
        headers() {
            return {
                Authorization: getToken(),
            }
        },
        importUrl() {
            return require('@/assets/images/import.png')
        },
        exportUrl() {
            return require('@/assets/images/export.png')
        }
    },
    methods: {
        handleAdd() {
            this.$emit('handleAdd')
        },
        multiDelete() {
            this.$emit('multiDelete')
        },
        handleImport() {
            this.$emit('handleImport')
        },
        handleExport() {
            this.$emit('handleExport')
        },
        handleAvatarSuccess(res) {
            if (res.code === '200') {
                this.$message.success('导入成功')
                this.$emit('handleSuccess')
            } else {
                this.$emit('downloadErrorList', res)
            }
        },
        beforeAvatarUpload(file) {
            const isType = ['.xlsx', '.xls'].includes(file.name.substring(file.name.lastIndexOf('.')))
            const isLt2M = file.size / 1024 / 1024 < 10;
            const is50 = file.name.length < 50
            if (!is50) {
                this.$message.error('上传文件名称不能超过50个字符!');
                return false
            }
            if (!isType) {
                this.$message.error('上传文件只能是 .xls或.xlsx 格式!');
                return false
            }
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 10MB!');
                return false
            }
        },
    }
}
</script>

<style scoped lang="scss">
.operation-btn-view {
    display: flex;
    box-sizing: border-box;
    padding: 0px 0px 10px 0px;
    overflow: hidden;
    background-color: #fff;

}

.el-button {
    font-size: 14px;
    margin-left: 10px;
}

.download-btn {
    border: 0;
    background-color: #3dc5f2;

    &:hover {
        background-color: #5ecff5;
    }
}

.upload-demo {
    display: inline-block;
}
.btn-right{
    display: flex;
}
</style>
