<template>
    <div class="common-form">
        <el-form :model="ruleForm" :rules="rules" label-width="auto" ref="ruleForm">
            <el-form-item label="宿舍名称" prop="roomName">
                <el-input v-model="ruleForm.roomName" placeholder="请输入" clearable maxlength="50" show-word-limit
                    class="input-width-medium"></el-input>
            </el-form-item>
            <el-form-item label="宿舍楼" prop="dormitoryId">
                <el-select v-model="ruleForm.dormitoryId" clearable filterable @change="dormitoryChange"
                    class="input-width-medium">
                    <el-option v-for="(item, index) in dormitoryList" :key="index" :value="item.id"
                        :label="item.dormitoryName"></el-option>
                </el-select>
            </el-form-item>
            <div class="tips">
                无宿舍可选？请点击 <span @click="dialogBtn">宿舍楼管理</span> 进行添加
            </div>
            <el-form-item label="楼层" prop="floorId">
                <el-select v-model="ruleForm.floorId" clearable filterable class="input-width-medium">
                    <el-option v-for="(item, index) in floorList" :key="index" :value="item.id"
                        :label="item.floorName"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="床位数" prop="bedNum">
                <el-input-number v-model="ruleForm.bedNum" :min="0" :max="30" controls-position="right"
                    placeholder="请输入" clearable maxlength="50" show-word-limit
                    class="input-width-medium"></el-input-number>
            </el-form-item>
            <el-form-item label="宿舍类型" prop="roomType">
                <el-radio v-model="ruleForm.roomType" label="1">男</el-radio>
                <el-radio v-model="ruleForm.roomType" label="2">女</el-radio>
            </el-form-item>
            <div style="text-align: right">
                <el-button @click="handleClose" type="enquiry">取消</el-button>
                <el-button @click="submitForm" type="primary">确定</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
export default {
    props: {
        ruleForm: {
            type: Object,
        },
        rules: {
            type: Object,
        },
        dormitoryList: {
            type: Array,
        },
        floorList: {
            type: Array,
        },
    },
    methods: {
        submitForm() {
            this.$refs.ruleForm.validate((val) => {
                if (val) {
                    this.$emit("submitForm");
                }
            });
        },
        dialogBtn() {
            this.$emit("dialogBtn");
        },
        handleClose() {
            this.$emit("handleClose");
        },
        dormitoryChange(data) {
            this.$emit("dormitoryChange", data);
        },
    },
};
</script>

<style scoped>
.tips {
    width: fit-content;
    height: fit-content;
    /* border: 1px solid #dcdfe6; */
    font-size: 12px;
    padding: 0px;
    position: absolute;
    z-index: 100;
    right: 50px;
    top: 161px;
    user-select: none;
}

.tips span {
    color: #409eff;
    cursor: pointer;
}

/deep/.el-input__inner {
    text-align: left;
}
</style>
