<template>
    <div class="statistics-data">
        <div class="statistics-item">
            <span class="title">宿舍概况</span>
        </div>
        <div class="statistics-item">
            <img :src="dormitoryTotalUrl" alt="">
            <span class="title">宿舍总数</span>
            <span class="num">{{ statisticsData.roomAllNum }}</span>
        </div>
        <div class="statistics-item">
            <img :src="bedNumUrl" alt="">
            <span class="title">床位总数</span>
            <span class="num">{{ statisticsData.bedAllNum }}</span>
        </div>
        <div class="statistics-item">
            <img :src="laveBenNumUrl" alt="">
            <span class="title">剩余床位</span>
            <span class="num">{{ statisticsData.remainingAllNum }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "statisticsData",
    props: {
        statisticsData: {
            type: Object
        }
    },
    computed: {
        dormitoryTotalUrl() {
            return require('@/assets/images/dormitory-total.png')
        },
        bedNumUrl() {
            return require('@/assets/images/bed-num.png')
        },
        laveBenNumUrl() {
            return require('@/assets/images/lave-bed-num.png')
        },
    }
}
</script>

<style scoped lang="scss">
.statistics-data {
    display: flex;
    background-color: #fff;
    padding: 15px 24px;
    border-radius: 0px 4px 4px 4px;
   .statistics-item {
        display: flex;
        align-items: center;
        color: #333333;
        font-size: 14px;
        margin-right: 50px;
        padding-right: 50px;
        white-space: normal;

        &:first-child {
            color: #737373;
            font-weight: bold;
            border-right: 1px solid #D7D9DB;
        }

        .title {
            white-space: nowrap;
        }

        img {
            margin-right: 8px;
        }

        .num {
            margin-left: 10px;
            font-weight: bold;
            color: #7A8A99;
        }
    }
}
</style>
