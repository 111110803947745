import { render, staticRenderFns } from "./dialogFormData.vue?vue&type=template&id=5c3386be&scoped=true"
import script from "./dialogFormData.vue?vue&type=script&lang=js"
export * from "./dialogFormData.vue?vue&type=script&lang=js"
import style0 from "./dialogFormData.vue?vue&type=style&index=0&id=5c3386be&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c3386be",
  null
  
)

export default component.exports